// font-awesome
@import "../../node_modules/font-awesome/scss/variables";
@import "../../node_modules/font-awesome/scss/mixins";
@import "../../node_modules/font-awesome/scss/path";
@import "../../node_modules/font-awesome/scss/core";
@import "../../node_modules/font-awesome/scss/larger";
@import "../../node_modules/font-awesome/scss/fixed-width";
// @import "../../node_modules/font-awesome/scss/list";
// @import "../../node_modules/font-awesome/scss/bordered-pulled";
// @import "../../node_modules/font-awesome/scss/animated";
// @import "../../node_modules/font-awesome/scss/rotated-flipped";
// @import "../../node_modules/font-awesome/scss/stacked";
@import "../../node_modules/font-awesome/scss/icons";
@import "../../node_modules/font-awesome/scss/screen-reader";
